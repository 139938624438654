import React from "react";

function TermsAndConditions() {
  const styles = {
    container: {
      maxWidth: "800px",
      margin: "0 auto",
      padding: "20px",
      fontFamily: "Arial, sans-serif",
      color: "#333",
      lineHeight: "1.6",
    },
    title: {
      fontSize: "2.5em",
      marginBottom: "10px",
      textAlign: "center",
      color: "#2c3e50",
    },
    effectiveDate: {
      fontSize: "1em",
      marginBottom: "20px",
      textAlign: "center",
      color: "#95a5a6",
    },
    intro: {
      fontSize: "1.2em",
      marginBottom: "30px",
      textAlign: "justify",
    },
    section: {
      marginBottom: "40px",
    },
    heading: {
      fontSize: "1.8em",
      marginBottom: "10px",
      color: "#34495e",
    },
    list: {
      listStyleType: "none",
      paddingLeft: 0,
    },
    listItem: {
      marginBottom: "10px",
      textAlign: "justify",
    },
    strong: {
      color: "#2980b9",
    },
    closingStatement: {
      fontSize: "1.2em",
      marginTop: "40px",
      textAlign: "center",
      color: "#34495e",
    },
    link: {
      color: "#2980b9",
      textDecoration: "none",
    },
    linkHover: {
      textDecoration: "underline",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Terms and Conditions for Snapsetu</h1>
      <p style={styles.effectiveDate}>Effective Date: 10-08-2024</p>
      <p style={styles.intro}>
        Welcome to Snapsetu ("the App"). These Terms and Conditions outline the
        rules and regulations for the use of the Snapsetu mobile application. By
        accessing this App, we assume you accept these terms and conditions. Do
        not continue to use Snapsetu if you do not agree to all of the terms and
        conditions stated on this page.
      </p>

      <section style={styles.section}>
        <h2 style={styles.heading}>1. Acceptance of Terms</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            By using Snapsetu, you agree to be bound by these Terms and
            Conditions, including our Privacy Policy. If you do not agree with
            any part of these terms, you may not access or use the App.
          </li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.heading}>2. User Responsibilities</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            You must provide accurate and complete information when creating
            your Snapsetu account and promptly update any changes.
          </li>
          <li style={styles.listItem}>
            You are responsible for maintaining the confidentiality of your
            account credentials and for all activities that occur under your
            account.
          </li>
          <li style={styles.listItem}>
            You agree not to engage in any illegal, unauthorized, or
            inappropriate use of the App, including but not limited to violating
            intellectual property rights, harassing other users, or distributing
            harmful content.
          </li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.heading}>3. Content Ownership</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            All content created, uploaded, or shared on Snapsetu remains the
            sole property of the user who originated it. By sharing content, you
            grant Snapsetu a non-exclusive, royalty-free, transferable,
            sub-licensable, worldwide license to use, store, display, reproduce,
            modify, and distribute your content for the purpose of operating,
            promoting, and improving the App.
          </li>
          <li style={styles.listItem}>
            You agree that Snapsetu may access, preserve, and disclose your
            account information and content if required to do so by law or in
            good faith belief that such access, preservation, or disclosure is
            reasonably necessary to:
            <ul style={styles.list}>
              <li style={styles.listItem}>comply with legal obligations;</li>
              <li style={styles.listItem}>
                enforce these Terms and Conditions;
              </li>
              <li style={styles.listItem}>
                respond to claims that any content violates the rights of third
                parties;
              </li>
              <li style={styles.listItem}>
                protect the rights, property, or personal safety of Snapsetu,
                its users, or the public.
              </li>
            </ul>
          </li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.heading}>4. Limitation of Liability</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            Snapsetu and its affiliates, officers, directors, employees, agents,
            and suppliers shall not be liable for any indirect, incidental,
            special, consequential, or punitive damages, including but not
            limited to loss of profits, data, use, goodwill, or other intangible
            losses, resulting from:
            <ul style={styles.list}>
              <li style={styles.listItem}>
                your use or inability to use the App;
              </li>
              <li style={styles.listItem}>
                unauthorized access to or alteration of your transmissions or
                data;
              </li>
              <li style={styles.listItem}>
                any conduct or content of any third party on the App;
              </li>
              <li style={styles.listItem}>
                any content obtained from the App; or
              </li>
              <li style={styles.listItem}>
                unauthorized access, use, or alteration of your content.
              </li>
            </ul>
          </li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.heading}>5. Modifications</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            Snapsetu reserves the right to revise these Terms and Conditions at
            any time without prior notice. By continuing to use the App after
            changes are made, you agree to be bound by the revised Terms and
            Conditions. It is your responsibility to review these Terms and
            Conditions periodically for any updates.
          </li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.heading}>6. Governing Law</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            These Terms and Conditions are governed by and construed in
            accordance with the laws of [Your Country], without regard to its
            conflict of law provisions.
          </li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.heading}>7. Contact Us</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            If you have any questions, concerns, or requests regarding these
            Terms and Conditions or the practices of Snapsetu, please contact us
            at{" "}
            <a href="mailto:terms@snapsetu.com" style={styles.link}>
              terms@snapsetu.com
            </a>
            .
          </li>
        </ul>
      </section>

      <p style={styles.closingStatement}>
        By using Snapsetu, you acknowledge that you have read and understood
        these Terms and Conditions and agree to be bound by them.
      </p>
    </div>
  );
}

export default TermsAndConditions;
