import React, { useState } from "react";

const DeleteAccountForm = () => {
  const [email, setEmail] = useState("");
  const [confirmationSent, setConfirmationSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const response = await fetch(
        "https://app.snapsetu.com/api/account/delete",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send confirmation link");
      }

      setTimeout(() => {
        setConfirmationSent(true);
        setLoading(false);
      }, 1500); // Simulating delay for demo purposes
    } catch (error) {
      setError("Failed to send confirmation link. Please try again later.");
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Delete Your Account</h2>
      {confirmationSent ? (
        <div style={styles.confirmation}>
          <p>Confirmation link sent to your email.</p>
          <p>Please check your inbox.</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit} style={styles.form}>
          <label style={styles.label}>
            Email Address:
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              style={styles.input}
              required
              disabled={loading}
            />
          </label>
          <button type="submit" style={styles.button} disabled={loading}>
            {loading ? "Sending..." : "Send Confirmation"}
          </button>
          {error && <p style={styles.error}>{error}</p>}
        </form>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundColor: "#f2f2f2",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "400px",
    margin: "auto",
  },
  title: {
    marginBottom: "20px",
    color: "#333",
    textAlign: "center",
    fontSize: "28px",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "1px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  label: {
    marginBottom: "10px",
    color: "#555",
    textAlign: "left",
    width: "100%",
  },
  input: {
    padding: "12px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    width: "100%",
    fontSize: "14px",
    marginBottom: "15px",
    boxSizing: "border-box",
    outline: "none",
  },
  button: {
    padding: "14px 24px",
    backgroundColor: "#4caf50",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "uppercase",
    transition: "background-color 0.3s ease",
    width: "100%",
    marginTop: "15px",
  },
  confirmation: {
    textAlign: "center",
    color: "#555",
    fontSize: "16px",
  },
  error: {
    marginTop: "10px",
    textAlign: "center",
    color: "red",
    fontSize: "14px",
  },
};

export default DeleteAccountForm;
