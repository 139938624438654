import React from "react";

function PrivacyPolicy() {
  const styles = {
    container: {
      maxWidth: "1000px",
      margin: "0 auto",
      padding: "20px",
      fontFamily: "Arial, sans-serif",
      color: "#333",
      lineHeight: "1.8",
    },
    title: {
      fontSize: "2.5em",
      marginBottom: "10px",
      textAlign: "center",
      color: "#2c3e50",
    },
    effectiveDate: {
      fontSize: "1.2em",
      marginBottom: "20px",
      textAlign: "center",
      color: "#95a5a6",
    },
    intro: {
      fontSize: "1.1em",
      marginBottom: "30px",
      textAlign: "justify",
    },
    section: {
      marginBottom: "40px",
    },
    heading: {
      fontSize: "1.8em",
      marginBottom: "10px",
      color: "#34495e",
    },
    list: {
      listStyleType: "none",
      paddingLeft: 0,
    },
    listItem: {
      marginBottom: "15px",
      textAlign: "justify",
    },
    strong: {
      color: "#2980b9",
    },
    closingStatement: {
      fontSize: "1.1em",
      marginTop: "40px",
      textAlign: "center",
      color: "#34495e",
    },
    link: {
      color: "#2980b9",
      textDecoration: "none",
    },
    linkHover: {
      textDecoration: "underline",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Privacy Policy for Snapsetu bhojpuri community</h1>
      <p style={styles.effectiveDate}>Effective Date: August 18, 2024</p>
      <p style={styles.intro}>
        Welcome to Snapsetu bhojpuri community, reels and shorts status app. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our mobile application and related services. By using Snapsetu, you consent to the practices described in this policy.
      </p>

      <section style={styles.section}>
        <h2 style={styles.heading}>1. Information We Collect</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            <strong style={styles.strong}>Personal Information:</strong> When you create an account with Snapsetu, we collect personal details such as your name, email address, phone number, profile picture, date of birth, and gender. This information is necessary to provide you with a personalized experience and to ensure the security of your account.
          </li>
          <li style={styles.listItem}>
            <strong style={styles.strong}>Content Information:</strong> We collect the content you create, upload, or share, including photos, videos, comments, and messages. This data is used to display and manage your posts and interactions within the app.
          </li>
          <li style={styles.listItem}>
            <strong style={styles.strong}>Usage Information:</strong> This includes data on how you interact with Snapsetu, such as your app usage patterns, device information, and engagement metrics. This helps us analyze trends and improve the app’s functionality.
          </li>
          <li style={styles.listItem}>
            <strong style={styles.strong}>Location Information:</strong> With your consent, we may collect information about your device’s location to provide location-based features, such as local content or services.
          </li>
          <li style={styles.listItem}>
            <strong style={styles.strong}>Log Data:</strong> Automatically collected data such as IP addresses, browser types, referring pages, and timestamps. This information helps us diagnose technical issues and understand user behavior.
          </li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.heading}>2. How We Use Your Information</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            We use personal information to create and manage your account, provide access to the app's features, and personalize your experience based on your preferences and activities.
          </li>
          <li style={styles.listItem}>
            Content information is used to display your posts, manage interactions, and enhance social features within the app. This includes sharing content with other users and providing feedback on your posts.
          </li>
          <li style={styles.listItem}>
            Usage information helps us analyze trends, optimize app performance, and improve user experience by identifying areas for enhancement and resolving issues.
          </li>
          <li style={styles.listItem}>
            Location information is utilized to offer location-specific features and services, improving the relevance of content and recommendations based on your location.
          </li>
          <li style={styles.listItem}>
            Log data is used for security purposes, such as preventing fraudulent activities and diagnosing technical issues. It also helps us understand how users interact with the app.
          </li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.heading}>3. Disclosure of Your Information</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            We do not sell, rent, or lease your personal information to third parties. However, we may share your information with trusted service providers who assist us in operating the app and delivering services to you. These providers are obligated to protect your information and use it solely for the purposes we specify.
          </li>
          <li style={styles.listItem}>
            We may disclose your personal information if required by law or in response to valid legal requests, such as subpoenas or court orders. This disclosure may be necessary to comply with legal obligations or protect our rights and interests.
          </li>
          <li style={styles.listItem}>
            Your public profile information, posts, and interactions may be visible to other users of the app. This visibility is intended to facilitate social interactions and content sharing within the app.
          </li>
          <li style={styles.listItem}>
            In the event of a merger, acquisition, or sale of all or part of our assets, your personal information may be transferred to the acquiring entity. We will notify you of any such changes and provide information on how your data will be handled.
          </li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.heading}>4. Data Security</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            We implement industry-standard security measures to protect your personal information from unauthorized access, use, or disclosure. These measures include encryption, secure servers, and access controls.
          </li>
          <li style={styles.listItem}>
            Despite these measures, no method of transmission or storage is completely secure. We cannot guarantee absolute security but strive to protect your information to the best of our ability.
          </li>
          <li style={styles.listItem}>
            You are responsible for maintaining the confidentiality of your account credentials. If you suspect any unauthorized access, please contact us immediately to take appropriate actions.
          </li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.heading}>5. Third-Party Links and Services</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            The app may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third parties. We encourage you to review their privacy policies before providing any personal information.
          </li>
          <li style={styles.listItem}>
            Some features of the app may include integrations with third-party services, such as social media platforms or payment processors. Your interactions with these services are governed by their respective privacy policies.
          </li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.heading}>6. Children’s Privacy</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            The app is intended for users who are at least 13 years old. We do not knowingly collect personal information from children under 13. If we become aware that we have collected information from a child under 13, we will take steps to delete it.
          </li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.heading}>7. International Data Transfers</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            Snapsetu is available globally. By using the app, you acknowledge that your information may be transferred to, stored, and processed in countries outside of your country of residence. These countries may have different data protection laws.
          </li>
          <li style={styles.listItem}>
            We take measures to ensure that your information is protected according to applicable data protection standards in the jurisdictions where it is processed.
          </li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.heading}>8. Your Data Protection Rights</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            <strong style={styles.strong}>Access:</strong> You have the right to request access to the personal information we hold about you.
          </li>
          <li style={styles.listItem}>
            <strong style={styles.strong}>Correction:</strong> You have the right to request the correction of inaccurate or incomplete personal information.
          </li>
          <li style={styles.listItem}>
            <strong style={styles.strong}>Deletion:</strong> You have the right to request the deletion of your personal information, subject to certain legal requirements.
          </li>
          <li style={styles.listItem}>
            <strong style={styles.strong}>Objection:</strong> You have the right to object to the processing of your personal information under certain conditions.
          </li>
          <li style={styles.listItem}>
            <strong style={styles.strong}>Restriction:</strong> You have the right to request a restriction on the processing of your personal information under specific circumstances.
          </li>
          <li style={styles.listItem}>
            To exercise these rights, please contact us at{" "}
            <a href="mailto:snapsetu@gmail.com" style={styles.link}>
              snapsetu@gmail.com
            </a>
            .
          </li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.heading}>9. Changes to This Privacy Policy</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            We may update this Privacy Policy from time to time. Any significant changes will be communicated by posting the revised policy within the app. Your continued use of the app after such changes constitutes your acceptance of the updated policy.
          </li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.heading}>10. Contact Us</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            For any questions, concerns, or requests related to this Privacy Policy, please contact us at{" "}
            <a href="mailto:snapsetu@gmail.com" style={styles.link}>
              snapsetu@gmail.com
            </a>
            .
          </li>
        </ul>
      </section>

      <p style={styles.closingStatement}>
        By using Snapsetu, you acknowledge that you have read and understood this Privacy Policy and consent to the collection, use, and disclosure of your personal information as described herein.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
